import styles from './styles.module.scss'
import {useEffect, useState} from "react";
// import useLongPress from "../../hooks/useLongPress";

const CounterInput = ({max = 25, min = 0, defaultValue = 0, onChange}) => {
    const [counter, setCounter] = useState(defaultValue);

    const incrementCounter = () => {
        console.log('deneme');
        counter < max && setCounter(x => x + 1)
    };

    const decrementCounter = () => {
        counter > min && setCounter(x => x - 1)
    };

    // const incLong = useLongPress(incrementCounter, ()=>onChange?.(), {delay:300});
    // const decLong = useLongPress(decrementCounter, ()=>onChange?.(), {delay:300});

    useEffect(() => {
        typeof counter === "number" && typeof onChange === "function" && onChange(counter)
    }, [counter, onChange])

    return (
        <div className={styles.counter}>
            <div className={styles.button} onClick={decrementCounter} >-</div>
            <div className={styles.count}>{counter}</div>
            <div className={styles.button} onClick={incrementCounter}>+</div>
        </div>
    )
}

export default CounterInput
