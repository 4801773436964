import React, {useEffect, useState} from "react";
import styles from './mint.module.scss';
import WalletConnectProvider from "@walletconnect/web3-provider";
import {abi} from "../../abi";
import {toast} from 'react-toastify';
import borderImage from '../../assets/borderEffect.png';
import image from '../../assets/Untitled-3.jpg';
import config from "../../config";
import Web3 from "web3";
import CounterInput from "../../components/CounterInput";
import {ClipLoader} from "react-spinners";

const Mint = () => {
    const [contract, setContract] = useState();
    const [currentTokenId, setCurrentTokenId] = useState(0);
    const [walletAddress, setWalletAddress] = useState('');
    const [containerSituation, setContainerSituation] = useState('connect');
    const [mintAmount, setMintAmount] = useState(0);
    const [walletAddressToDisplay, setWalletAddressToDisplay] = useState('');
    // const [walletsMintAmount, setWalletsMintAmount] = useState(0);
    // const [notAllowedError, setNotAllowedError] = useState(0);
    // const [allowedMintAmount, setAllowedMintAmount] = useState(0);
    const [web3, setWeb3] = useState(undefined);
    const [mintLoading, setMintLoading] = useState(false)

    useEffect(() => {
        window.ethereum && setWeb3(new Web3(window.ethereum));
    }, [])

    useEffect(() => {
        (async () => {
            if (web3) {
                setContract(new web3.eth.Contract(abi, config.web3.contractAddress))
                const accounts = await web3.eth.getAccounts()
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0])


                }
            }
        })()
    }, [web3])

    useEffect(() => {
        (async () => {
            await contract.methods.getCurrentTokenCount().call().then(r => {
                setCurrentTokenId(r - 1);
            })

        })()
    }, [contract])

    useEffect(() => {
        walletAddress.length > 5 && setWalletAddressToDisplay((walletAddress.toString().substr(0, 5)) + '...' + walletAddress.toString().substr(walletAddress.toString().length - 4, 4))
    }, [walletAddress])

    useEffect(() => {
        if (window.ethereum && window.ethereum.on) {
            window.ethereum.autoRefreshOnNetworkChange = true

            const handleConnect = async () => {
                window.ethereum.chainId === config.web3.chainId ? setContainerSituation('mint') : setContainerSituation('connect')
            }
            const handleChainChanged = async (chainId) => {
                chainId === config.web3.connectionParams[0].chainId ? setContainerSituation('mint') : setContainerSituation('connect')
            }
            const handleAccountsChanged = async (accounts) => {
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0]);
                } else {
                    setContainerSituation('connect')
                }
            }

            window.ethereum.on('connect', handleConnect)
            window.ethereum.on('chainChanged', handleChainChanged)
            window.ethereum.on('accountsChanged', handleAccountsChanged)

            return () => {
                if (window.ethereum.removeListener) {
                    window.ethereum.removeListener('connect', handleConnect)
                    window.ethereum.removeListener('chainChanged', handleChainChanged)
                    window.ethereum.removeListener('accountsChanged', handleAccountsChanged)
                }
            }
        }
    }, [])

    const connectWallet = async () => {
        if (!web3) {
            window.open('https://metamask.io/download')
        } else {
            await window.ethereum.request({method: 'eth_requestAccounts'});
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: config.web3.connectionParams
            })
            setWalletAddress(web3.eth.currentProvider.selectedAddress);
            setContainerSituation('mint');
            toast.success('Connected Succesfully!');
        }
    }
    const mint = async () => {
        if (mintAmount < 1 || !contract) return

        setMintLoading(true)
        console.log(contract);
        await setContract(new web3.eth.Contract(abi, config.web3.contractAddress));
        console.log(contract);
        const mintValue = String(mintAmount * 0.0444);
        const accounts = await web3.eth.getAccounts();
        const accountBalance = web3.utils.fromWei(await web3.eth.getBalance(accounts[0]));
        if (accountBalance < +mintValue) {
            setMintLoading(false);
            toast.error('You don\'t have enough ethers on your wallet!');
            return;
        }
        contract.methods
            .summint(mintAmount)
            .estimateGas({
                value: web3.utils.toWei(String(mintValue), 'ether'),
                from: accounts[0],
                // gasPrice: 33881226434,
                // gasLimit: 55000
            }).then(gas => {
                console.log(gas);
            contract.methods.summint(mintAmount).send({
                from: accounts[0],
                value: web3.utils.toWei(String(mintValue), 'ether'),
                gasLimit: gas*2
            }).then(async res => {
                console.log(res);
                toast.success('Minted Successfully! You can see your NFTs on Opensea a few seconds later!')
                await contract.methods.getCurrentTokenCount().call().then(r => {
                    setCurrentTokenId(r - 1);
                })
                setMintLoading(false)
            }).catch(err => {
                console.log(err);
                toast.error('There is an error on your action!');
                setMintLoading(false)
            }).finally(() => {

            });

            setContainerSituation('mint');
        }).catch(err => {
            toast.error('There is an error on your action!');
        }).finally(() => {

        });


    }

    const walletConnect = async () => {
        console.log('Hello!');

        const provider = new WalletConnectProvider({
            infuraId: 'fec309452238457fa695106a5775cc3e',
        })

        await provider.enable();
        let tempweb3 = new Web3(provider);
        tempweb3.eth.getAccounts().then(r => {
            console.log(r);
        })
        setContainerSituation('mint');
        await setWeb3(tempweb3);

    }
    const toggleSummint = async () => {
        const accounts = await web3.eth.getAccounts();
        contract.methods.toggleSummint().send({from:accounts[0]}).then(r => console.log(r));
    }

    return (
        <div className={styles.containerWrapper}>
            <div className={styles.mintContainer}>
                {containerSituation === 'connect' ? <div className={styles.mintConnectWallet}>
                    <button className={styles.connectWalletButton}
                            onClick={() => connectWallet()}>{web3 ? 'CONNECT WALLET' : 'INSTALL METAMASK'}
                    </button>
                    <button className={styles.mobileMetamaskButton} onClick={() => walletConnect()}>
                        CONNECT TO METAMASK
                    </button>
                </div> : containerSituation === 'mint' ?
                    <>

                        <div className={styles.mintContainerMint}>
                            <div className={styles.mobileMint}>
                                <div className={styles.mintInnerContainer}>
                                    <div className={styles.mintInput}>
                                        <CounterInput onChange={setMintAmount}/>
                                    </div>
                                    <button
                                        className={mintLoading ? styles.mintLoadingButton : styles.mintButton}
                                        onClick={mint}
                                        disabled={mintLoading}
                                    >{mintLoading ? <ClipLoader color="#fff" size={16}/> : 'MINT NOW!'}
                                    </button>
                                    <div className={styles.mintInfo}>
                                        {currentTokenId !== 333 ? <p>{currentTokenId} Of 333</p> : <p>Sold Out</p>}

                                        <p className={styles.walletAddress}>Wallet Address: {walletAddressToDisplay}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.projectCardOuterContainer}>
                                <div className={styles.projectCardContainer}>

                                    <div className={styles.innerContainer}>
                                        <img className={styles.borderImageFirst} loading='lazy' src={borderImage}
                                             alt=""/>
                                        <img className={styles.borderImageSecond} loading='lazy' src={borderImage}
                                             alt=""/>
                                        <div className={styles.projectCardLeft}
                                        >
                                            <img src={image}
                                                 className={styles.projectCardLeftImg}
                                                 loading='lazy' alt=""/>
                                        </div>
                                        <div className={styles.projectCardRight}>
                                            <div className={styles.projectCardRightTop}>
                                                <p>SkinRunners Genesis Serie: Phoenix NFTs</p>
                                            </div>
                                            <div className={styles.mintContainerMint}>
                                                <div className={styles.mintInnerContainer}>
                                                    <div className={styles.mintInput}>
                                                        <CounterInput onChange={setMintAmount}/>
                                                    </div>
                                                     <button
                                                        className={mintLoading ? styles.mintLoadingButton : styles.mintButton}
                                                        onClick={mint}
                                                        disabled={mintLoading}
                                                    >{mintLoading ? <ClipLoader color="#fff" size={16}/> : 'MINT NOW!'}
                                                    </button>
                                                   {/*<button onClick={toggleSummint}>TOGGLE </button>*/}
                                                    <div className={styles.mintInfo}>
                                                        {currentTokenId !== 66 ? <p>{currentTokenId} Of 66</p> :
                                                            <p>Sold Out</p>}
                                                     <p className={styles.walletAddress}>Wallet
                                                            Address: {walletAddressToDisplay}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    : <></>}

            </div>
        </div>
    )
}

export default Mint;
