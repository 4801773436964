module.exports = {
    web3: {
        contractAddress: '0x26D9Ad0d1275eCC6A16f3c7B60dE6084faDD0590',
        chainId: 1,
        connectionParams: [{
            chainId: '0x1'
        }]
        //
        // chainId: 4,
        // connectionParams: [{
        //     chainId: '0x4'
        // }]
    }
}
