import React from "react";
import rnvLogo from '../../assets/rocknverselogopure.png';
import discord from '../../assets/discord-line.png';
import instagram from '../../assets/instagram-line.png';
import youtube from '../../assets/yticon.png';
import medium from '../../assets/medium-line.png';
import twitter from '../../assets/twitter-line.png';
import styles from  './navbar.module.scss';
import opensea from '../../assets/Logomark-Transparent White.png'

const Navbar = () => {
    return (
        <div className={styles.navbarWrapper}>
            <div className={styles.navbarContainer}>
                <div className={styles.navbarLeft}>
                    <img src={rnvLogo} className={styles.rnvNavbarLogo} alt=''/>
                </div>
                <div className={styles.navbarCenter}>
                 <a href="https://opensea.io/rocknverse" target="_blank" rel="noreferrer"><img src={opensea} className={styles.socialIconsNavbar} alt=''/></a>
                  <a href="https://discord.gg/rocknverseofficial" target="_blank" rel="noreferrer" ><img src={discord} className={styles.socialIconsNavbar} alt=''/></a>
                   <a href="https://www.instagram.com/rocknverse/" target="_blank" rel="noreferrer" ><img src={instagram} className={styles.socialIconsNavbar} alt=''/></a>
                   <a href="https://www.youtube.com/c/ROCKNVERSE" target="_blank" rel="noreferrer" > <img src={youtube} className={styles.socialIconsNavbar} alt=''/></a>
                   <a href="https://medium.com/@rocknverse" target="_blank" rel="noreferrer" ><img src={medium} className={styles.socialIconsNavbar} alt=''/></a>
                   <a href="https://twitter.com/rocknverse" target="_blank" rel="noreferrer" ><img src={twitter} className={styles.socialIconsNavbar} alt=''/></a>
                </div>
                <div className={styles.navbarRight}>
                    <a className={styles.navbarLink} href='https://rocknverse.com'>Back To Rock'N'Verse &rarr;</a>
                </div>
            </div>
        </div>
    )
}

export default Navbar;
