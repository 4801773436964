import styles from './App.module.scss';
import video from './assets/RNW WEB MINT FINAL.mp4'
import Navbar from "./containers/navbar/Navbar";
import Mint from "./containers/mint/Mint";
import {ToastContainer} from "react-toastify";

function App() {
    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                limit={3}
                theme={"colored"}
            />
            <div className={styles.mainContainer}>
                <div className={styles.videoBackground}/>
                <video className={styles.video} autoPlay loop muted>
                    <source src={video} type='video/mp4'/>
                </video>
                <div className={styles.innerContainer}>
                    <Navbar/>
                    <Mint/>
                </div>
            </div>
        </>
    );
}

export default App;
